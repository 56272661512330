
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'TeacherIntro',
  props: {
    teacherInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const router = useRouter()
    const toPage = () => {
      if (router.hasRoute('otherHomePage')) {
        router.push('/mine/other/' + props.teacherInfo.userId)
      }
    }
    return {
      toPage
    }
  }
})
