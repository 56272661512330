
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'TeacherCourse',
  props: {
    courseList: {
      type: Array,
      default: () => []
    },
    teacherInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const activeName = ref([0])
    const router = useRouter()
    const toPage = () => {
      if (router.hasRoute('otherHomePage')) {
        router.push('/mine/other/' + props.teacherInfo.userId)
      }
    }
    const toCoursePage = (id: number) => {
      router.push('/coursedetail/' + id)
    }
    return {
      activeName,
      toPage,
      toCoursePage
    }
  }
})
