
import { timeFormat } from '@/utils/util'
import { Ref, ref, UnwrapRef } from 'vue'
import TeacherIntro from '@/views/teacher/components/TeacherIntro.vue'
import TeacherCourse from '@/views/teacher/components/TeacherCourse.vue'
import { selectCourseById, courseList, selectTeacherUserWork, workList } from '@/states/teacher'
import { useRouter,useRoute } from 'vue-router'
import { changeShareInfo } from '@/utils/wechat'
import { api } from '@/api/useAxios'
import { MmTeacher, createMmTeacher } from 'momai'

export default {
  name: 'Detail',
  components: {
    TeacherIntro,
    TeacherCourse
  },
  setup: () => {
    const router = useRouter()
    //const teacherInfo = router.currentRoute.value.query

    const { userId } = useRoute().params
    
    const teacherInfo = ref<MmTeacher>(createMmTeacher())
    const fetchTeacher = () => {
      api.get('/mmteacher/getbyuserid/'+userId).then((r) => {
        if(r){
          teacherInfo.value = r
          changeShareInfo({
            title: r.teacherName ? '导师-'+r.teacherName : process.env.VUE_APP_PAGE_TITLE,
            desc: r.teacherDesc ? r.teacherDesc : process.env.VUE_APP_PAGE_DESC,
            link: window.location.href,
            imgUrl: r.teacherAvatar ? r.teacherAvatar : process.env.VUE_APP_SHARE_IMG
          })
        }
      })
    }
    fetchTeacher()

    const active = ref(0)
    selectCourseById(Number(userId))
    selectTeacherUserWork({
      current: 1,
      size: 10,
      userId: Number(userId)
    })
    
    

    const tabList = Object.freeze([
      { id: 0, label: '学习圈' },
      { id: 1, label: '课程' },
      { id: 2, label: '简介' }
    ])
    let titleactiveTab: Ref<UnwrapRef<number>> = ref(0)
    const changeTab = (_activeTab: number) => {
      titleactiveTab.value = _activeTab
    }
    return {
      tabList, changeTab, titleactiveTab,
      courseList,
      workList,
      teacherInfo,
      active,
      timeFormat,
      goBack: () => {
        router.go(-1)
      }
    }
  }
}
