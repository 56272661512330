import { ref } from 'vue'
import { getcourseById, getTeacherUserWork } from '@/api/user/teacher'

const courseList = ref<any>([])

const workList = ref<any>([])

const selectCourseById = (teacherId: number) => {
  getcourseById(teacherId).then(r => {
    courseList.value = r
  })
}

const selectTeacherUserWork = (params: any) => {
  getTeacherUserWork(params).then((r: any) => {
    workList.value = r.records
  })
}

export {
  courseList,
  selectCourseById,
  workList,
  selectTeacherUserWork
}
